body {
  margin: 0;
}

body,
input,
textarea,
button {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge { 
  visibility: hidden;
}
